import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Formik, Form } from 'formik';
import { LoggedAccount, RootState } from '../../store/config/types';
import { AppActions } from '../../store/config/ActionTypes';
import { updateAccount, getAccount, logout } from '../../store/actions/auth.actions';
import { setToast } from '../../store/actions/toast.actions';
import schemas from '../../utils/schemas';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import GoogleAutocomplete from '../../components/SpecialInput/GoogleAutocomplete/GoogleAutocomplete';
import InputField from '../../components/SpecialInput/InputField';
import MaskedPhoneInput from '../../components/MaskedPhoneInput/MaskedPhoneInput';
import Spinner from '../../components/Spinner/Spinner';
import TimezoneInput from '../../components/TimezoneInput/TimezoneInput';
import {
  PaymentIcon,
  NotificationSettingsIcon,
  UserManagerIcon,
  WarehouseUsersIcon,
  CourierIcon,
  CustomersIcon,
  VehiclesIcon,
  WarehousesIcon,
} from '../../assets/images';
import { Grid, Link, Typography } from '@mui/material';
import { IconButton } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { hasPermission } from '../../utils/permissions';

interface ProfileValues {
  firstName: string;
  lastName: string;
  phoneNumber: number;
  email: string;
  homeAddress: string;
  timezone: string;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  getAccount: (shipperId: number) => dispatch(getAccount(shipperId)),
  updateAccount: (shipperId: number, shipperData: LoggedAccount) => dispatch(updateAccount(shipperId, shipperData)),
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
  logout: () => dispatch(logout()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Profile({ auth, updateAccount, setToast, getAccount, logout }: PropsFromRedux) {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [address, setAddress] = useState<string>(auth.account?.homeAddress || '');

  useEffect(() => {
    if (submitting && !auth.updatingAccount) {
      if (auth.updateAccountSuccess) {
        setSubmitting(false);
        setToast('Profile successfully updated', 'success');
      }
      if (auth.updateAccountErrorMessage !== null) {
        setSubmitting(false);
        setToast('An error has ocurred while updating profile', 'danger');
      }
    }
  }, [
    submitting,
    setSubmitting,
    auth.updatingAccount,
    auth.updateAccountSuccess,
    auth.updateAccountErrorMessage,
    setToast,
  ]);

  const submit = async (values: ProfileValues) => {
    setSubmitting(true);
    const updatedAccount: LoggedAccount = {
      ...auth.account,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: Number(values.phoneNumber),
      email: values.email,
      homeAddress: address,
      timezone: values.timezone,
    };

    await updateAccount(auth.account!.shipper?.shipperId ?? 0, updatedAccount);
    await getAccount(auth.account!.shipperUserId ?? 0);
  };

  if (auth.account === null) {
    return <Spinner />;
  }

  return (
    <div className="profile">
      <Formik
        initialValues={{
          firstName: auth.account?.shipper?.firstName || '',
          lastName: auth.account?.shipper?.lastName || '',
          phoneNumber: auth.account?.shipper?.phoneNumber || 0,
          email: auth.account?.shipper?.email || '',
          homeAddress: auth.account?.shipper?.homeAddress! || '',
          companyName: auth.account?.shipper?.companyName || '',
          timezone: auth.account?.shipper?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        }}
        validationSchema={schemas.ProfileSchema}
        onSubmit={submit}
      >
        {({ initialValues, setFieldValue }) => (
          <Form>
            <Card profile>
              <CardHeader color="primary" className="card-header">
                <Grid container alignItems="center">
                  <Grid item flexGrow={1} textAlign="left">{`${auth.account?.shipper?.companyName}`}</Grid>
                  <Grid>
                    <IconButton
                      onClick={() => {
                        logout();
                      }}
                    >
                      <ExitToApp style={{ color: 'white' }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardBody>
                {/* <h3 className="section-title">Personal info</h3>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="First name" name="firstName" />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Last name" name="lastName" />
                  </GridItem>
                </GridContainer> */}
                <GridContainer justify="center">
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Company Email" name="email" disabled={true} />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <InputField placeholder="Company name" name="companyName" disabled={true} />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={3}>
                    <MaskedPhoneInput
                      placeholder="Company Phone number"
                      name="phoneNumber"
                      setfieldvalue={setFieldValue}
                    />
                  </GridItem>
                  <GoogleAutocomplete
                    address={address}
                    setAddress={setAddress}
                    setFieldValue={setFieldValue}
                    usesAllGoogleProps={false}
                    inputSize={6}
                    inputName={'address'}
                    placeholderName={'Company Address'}
                  />
                  <GridItem xs={12} sm={3}>
                    <TimezoneInput
                      name="timezone"
                      setfieldvalue={setFieldValue}
                      defaultValue={initialValues.timezone}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button className="submit-button" type="submit" color="primary">
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Form>
        )}
      </Formik>
      <Grid container>
        <SettingsButton href="/customers" caption="Customers" icon={CustomersIcon} />
        <SettingsButton href="/couriers" caption="Couriers" icon={CourierIcon} />
        <SettingsButton href="/vehicles" caption="Vehicles" icon={VehiclesIcon} />
        <SettingsButton href="/warehouses" caption="Warehouses" icon={WarehousesIcon} />
        <SettingsButton href="/payment" caption="Payment Methods" icon={PaymentIcon} />
        <SettingsButton href="/user-control" caption="User Management" icon={UserManagerIcon} />
        {hasPermission(auth?.account, ['warehouse.userManagement']) && (
          <SettingsButton href="/settings/wms-users" caption="WMS Users" icon={WarehouseUsersIcon} />
        )}
        <SettingsButton href="/notification-settings" caption="Notification Setting" icon={NotificationSettingsIcon} />
      </Grid>
    </div>
  );
}

function SettingsButton(props: { href: string; icon: string; caption: string }) {
  return (
    <Grid item p={2} xs={6} md={4} lg={3}>
      <Link href={props.href} className="setting-link">
        <img src={props.icon} alt={props.caption} width={32} />
        <Typography variant="button">{props.caption}</Typography>
      </Link>
    </Grid>
  );
}
export default connector(Profile);
